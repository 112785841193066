import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ImageLocal, OrderDisplayInfo } from "./quickUploadPod.interface";
import { Shipment } from "@wearewarp/types/data-model";
import { ApiService } from "@services/api.service";
import { Const } from "@const/Const";
import { jsPDF } from "jspdf"; //sử dụng để tạo PDF
import * as pdfjsLib from "pdfjs-dist"; //sử dụng để đọc file PDF
pdfjsLib.GlobalWorkerOptions.workerSrc =
  "assets/mozilla/pdfjs-2.13.216/pdf.worker.min.js";

@Injectable({
  providedIn: 'root'
})
export class QuickUploadPodService {
  public defaultImageGroupName = "default-group";
  public images: BehaviorSubject<ImageLocal[]> = new BehaviorSubject([]);
  public imageSelectedIndex: BehaviorSubject<number> = new BehaviorSubject(null);
  public activeTabIndex: BehaviorSubject<number> = new BehaviorSubject(0);
  public searchText: BehaviorSubject<string> = new BehaviorSubject(null);
  public formInfoWidth: BehaviorSubject<number> = new BehaviorSubject(50);
  public tabs: BehaviorSubject<any[]> = new BehaviorSubject([
    {
      name: 'Search',
      closable: false,
      type: "search"
    },
    // {
    //   name: 'Search AI',
    //   closable: false,
    //   type: "search-ai"
    // },
  ]);
  private readonly defaultImageSize:any = {
    "height": 1920 * 2,
    "width": 1080 * 2
  }


  constructor(private api: ApiService) {

  }
  getSelectedImage() {
    const index = this.imageSelectedIndex.getValue();
    if (index === null) return null;
    return this.images.getValue()[index];
  }

  async setSelectedIndex(index: number) {
    this.imageSelectedIndex.next(index);
    //this.extractTextFromImage(index);
  }
  getSelectedIndex() {
    return this.imageSelectedIndex.getValue();
  }

  addImageToGroup(image: ImageLocal, groupName: string = this.defaultImageGroupName) {
    image.groups = [...(image.groups || []), groupName];
    this.images.next(this.images.getValue());
  }
  removeImageFromGroup(image: ImageLocal, groupName: string = this.defaultImageGroupName) {
    image.groups = image.groups?.filter(group => group !== groupName);
    this.images.next(this.images.getValue());
  }
  toggleImageToGroup(image: ImageLocal, groupName: string = this.defaultImageGroupName) {
    if (image.groups?.includes(groupName)) {
      this.removeImageFromGroup(image, groupName);
    }
    else {
      this.addImageToGroup(image, groupName);
    }
  }
  clearGroup(groupName: string = this.defaultImageGroupName) {
    this.images.next(this.images.getValue().map(image => {
      if (image.groups?.includes(groupName)) {
        image.groups = image.groups.filter(group => group !== groupName);
      }
      return image;
    }));
    this.images.next(this.images.getValue());
  }
  getImagesByGroup(groupName: string = this.defaultImageGroupName) {
    return this.images.getValue().filter(image => image.groups?.includes(groupName));
  }


  addImage(image: ImageLocal) {
    this.images.next([...this.images.getValue(), image]);
    if (!this.imageSelectedIndex.getValue()) {
      this.setSelectedIndex(0);
    }
  }
  removeImage(image: ImageLocal) {
    const imageIndex = this.images.getValue().findIndex(img => img.url == image.url);
    const isBeforeSelected = imageIndex <= this.getSelectedIndex();

    //xử lý lại selected
    let newSelectedIndex = 0
    if (isBeforeSelected) {
      //nếu ảnh bị xoá đứng trước selected và không phải ảnh đầu tiên, thì dồn mảng
      if (this.getSelectedIndex() > 0) newSelectedIndex = this.getSelectedIndex() - 1;
    }
    else {
      //nếu đứng sau selected thì selected không đổi
      newSelectedIndex = this.getSelectedIndex()
    }

    this.images.next(this.images.getValue().filter(i => i.url !== image.url));
    this.setSelectedIndex(newSelectedIndex)

  }
  clearImages() {
    this.images.getValue().map(image => {
      URL.revokeObjectURL(image.url)
    });
    this.images.next([]);
  }

  openOrderDetail(order: OrderDisplayInfo) {
    const indexOfExistTab = this.tabs.getValue().findIndex(tab => tab.type === "order-detail" && tab.data.orderId === order.orderId);
    if (indexOfExistTab !== -1) {
      this.activeTabIndex.next(indexOfExistTab);
      return;
    }
    let name = order.orderWarpId;
    if (order.shipmentWarpIds.length == 1) {
      name = order.shipmentWarpIds[0];
    }

    this.tabs.next([...this.tabs.getValue(), {
      name: name,
      closable: true,
      type: "order-detail",
      data: {
        orderId: order.orderId
      }
    }]);
    this.activeTabIndex.next(this.tabs.getValue().length - 1);
  }

  closeTabByIndex(index: number) {
    this.tabs.next(this.tabs.getValue().filter((_, i) => i !== index));
  }

  setActiveTab(index: number) {
    this.activeTabIndex.next(index);
  }

  updateSearchText(word: string) {
    if (this.searchText.getValue() === word) return;
    this.searchText.next(word);
  }

  analyzeAll() {
    this.images.getValue().map((image, index) => {
      this.extractTextFromImage(index);
    });
  }

  //PDF Utils
  public async convertPdfToImages(file: File) {
    const pdfDoc = await pdfjsLib.getDocument(URL.createObjectURL(file)).promise;
    let promises = [];
    for (let i = 1; i <= pdfDoc.numPages; i++) {
      promises.push(this.getBlobFromPdfPage(pdfDoc, i))
    };
    return await Promise.all(promises);
  }

  public async convertImagesToPdf(images: ImageLocal[]) {
    const pdfDoc = new jsPDF()
    pdfDoc.deletePage(1);
    for (let index in images) {
      const image = images[index];
      const blob = image.blob;
      const fileName = image.name || `image-${index}.jpg`;
      const img = new Image();
      img.src = URL.createObjectURL(blob);
      await new Promise(resolve => img.onload = resolve);
      const width = img.width;
      const height = img.height;
      pdfDoc.addPage([width, height], width > height ? 'l' : 'p');
      pdfDoc.addImage(img, 'JPEG', 0, 0, width, height);
    }
    return pdfDoc.output('blob');
  }

  public async downloadImage(image: ImageLocal) {
    const fileName = image.name || "image.jpg";

    // window.open(URL.createObjectURL(image.blob), '_blank');
    let link = document.createElement('a');
    link.href = image.url;
    link.download = fileName;
    link.target = "_blank";
    document.body.appendChild(link);
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    link.remove();
    await new Promise(resolve => setTimeout(resolve, 100));
  }

  //API
  async extractTextFromImage(index) {
    let image = this.images.getValue()[index];
    if (!image) return;
    if (image.extractedData) return;
    if (image.analyzing) return;
    image.analyzing = true;

    let formData = new FormData();
    formData.append("file", new File([image.blob], `image-${Date.now}.jpg`));
    // formData.append("params", JSON.stringify({}));
    const response = await this.api.postFormData(`${Const.APIV2(Const.APIURI_PODS)}/extract-text-from-image`, formData).toPromise();

    //assign extracted data to image
    image.extractedData = response.data;
    this.images.next(this.images.getValue());
    image.analyzing = false;
    return
  }

  public async getShipments({ keyword }: { keyword: string }) {
    if (!keyword) return [];
    // https://gw.wearewarp.com/api/v2/orders?skip=0&limit=10&search=1407821
    const result = await this.api.GET(`${Const.APIV2(Const.APIURI_ORDERS)}/?search=${keyword}`).toPromise();
    return result?.data?.list_data || []
  }

  public async getOrderDetail(orderId: string) {
    const result = await this.api.GET(`${Const.APIV2(Const.APIURI_ORDERS)}/${orderId}`).toPromise();
    return result?.data;
  }

  public async getAllChildrens(shipmentId: string) {
    const result = await this.api.GET(`${Const.APIV2(Const.APIURI_SHIPMENTS)}/${shipmentId}/all-childrens`).toPromise();
    return result?.data?.list_data || []
  }


  private async getDataBeforeUploadPOD(shipment: Shipment) {
    const jobId = shipment.lastJobId
    if (!jobId) {
      throw new Error("Shipment has not been routed yet.")
    }
    const jobResponse = await this.api.GET(`${Const.APIV2(Const.APIURI_JOBS)}/${jobId}`).toPromise();
    if (!jobResponse.data) {
      throw new Error("Job not found.")
    }
    const tasks = jobResponse?.data?.tasks;
    const shipmentDropoffTask = tasks.find(task =>
      task.type === Const.TaskType.DROPOFF
      && task.shipmentId === shipment.id
    );
    if (!shipmentDropoffTask) {
      throw new Error("Shipment dropoff task not found.")
    }
    return {
      jobId,
      shipmentDropoffTask
    }
  }

  public async addImagesToShipment(images: ImageLocal[], shipment: Shipment) {
    const { jobId, shipmentDropoffTask } = await this.getDataBeforeUploadPOD(shipment);

    let fileInfo = {
      blob: null,
      name: null,
    }
    if (images.length > 1) {
      //convert images to pdf
      const pdfBlob = await this.convertImagesToPdf(images);
      fileInfo.blob = pdfBlob;
      fileInfo.name = `${images[0].name || 'image'}.pdf`;
    }
    else {
      fileInfo.blob = images[0].blob;
      fileInfo.name = images[0].name;
    }
    //add POD
    let apiUrl = `${Const.APIV2(Const.APIURI_TASKS)}/uploadPodForListTasksOfStop`;
    let formData = new FormData();
    const jsonData = {
      'taskIds': [shipmentDropoffTask.id],
    }
    formData.append("params", JSON.stringify(jsonData));
    formData.append("uploadPOD", new File([fileInfo.blob], fileInfo.name || 'image.jpg'));
    const result = await this.api.postFormData(apiUrl, formData).toPromise();
   
    await this.confirmPOD(result.data?.uploadInfo?.podItem, [shipmentDropoffTask.id]);

    images.map(image => {
      image.addedToShipments = [...(image.addedToShipments || []), shipment];
    });
    this.images.next(this.images.getValue());
    return result;
  }

  async confirmPOD(podFileId: string, taskIds: string[]) {
    let params = {
      taskIds: taskIds,
      "uploadPodFileId": podFileId
    };
    let url = `${Const.APIV2(Const.APIURI_TASKS)}/confirmPodForListTasksOfStop`;
    const result = await this.api.POST(url, params).toPromise();
    return result;
  }


  private async getBlobFromPdfPage(pdfDoc, pageNumber): Promise<Blob> {
    const page = await pdfDoc.getPage(pageNumber);
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // select scale for image to fit
    const unscaledViewport = page.getViewport({scale: 1});
    const scale = Math.min((this.defaultImageSize.width / unscaledViewport.width), (this.defaultImageSize.height / unscaledViewport.height));
    const viewport = page.getViewport({ scale });
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    await page.render({
      canvasContext: context,
      viewport: viewport
    }).promise;

    const base64 = canvas.toDataURL('image/jpeg');
    const blob = this.base64toBlob(base64.split(',')[1], 'image/jpeg');
    return blob;
  }

  private base64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

}